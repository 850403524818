// EVENT NAMES. THESE SHOULD BE ALIGNED WITH GTM

// Events that are not page specific and might happen on any page (errors, scrolls, etc.)
export const pageError = 'PAGE_ERROR';

// Events that are specific to pages that have forms
export const formAbandoned = 'FORM_ABANDONED';
export const formSubmitted = 'FORM_SUBMITTED';
export const formError = 'FORM_ERROR';

// Gift Card Events
export const GIFTCARD_EVENT_CATEGORY = 'GIFTCARD_EVENT_CATEGORY';
export const GIFTCARD_EXIT = 'GIFTCARD_EXIT';

// Events that are specific to the booking page form
export const BOOKING_FORM_EVENT_CATEGORY = 'BOOKING_FORM_EVENT_CATEGORY';
export const BOOKING_FORM_ADD_SERVICE = 'BOOKING_FORM_ADD_SERVICE';
export const BOOKING_FORM_ADD_TIME = 'BOOKING_FORM_ADD_TIME';
export const BOOKING_FORM_CREATE_USER = 'BOOKING_CREATE_USER';
export const BOOKING_FORM_FINISH_BOOKING = 'BOOKING_FORM_FINISH_BOOKING';
export const BOOKING_FORM_ERROR = 'BOOKING_FORM_ERROR';

// Events that are specific to the podcast pages
export const PODCAST_EVENT_CATEGORY = 'PODCAST_EVENT_CATEGORY';
export const PODCAST_PLAY = 'PODCAST_PLAY';
export const PODCAST_PAUSE = 'PODCAST_PAUSE';
export const PODCAST_INTERVAL = 'PODCAST_INTERVAL';
export const PODCAST_ERROR = 'PODCAST_ERROR';

// Others
export const CreateAccount = 'CREATE_ACCOUNT';

declare global {
	interface Window {
		dataLayer: any;
	}
}

// GENERIC
export const pushEvent = (
	eventCategory: string,
	eventAction: string,
	eventLabel: string,
	eventValue: any
) => {
	if (typeof window !== 'undefined' && window.dataLayer) {
		window['dataLayer'].push({
			event: 'gaEvent',
			eventCategory: eventCategory,
			eventAction: eventAction,
			eventLabel: eventLabel,
			eventValue: eventValue,
		});
	}
};

export const pushValues = (values: any) => {
	if (typeof window !== 'undefined' && window.dataLayer) {
		window['dataLayer'].push(values);
	}
};

export const gaEventLogin = () => {
	if (typeof window !== 'undefined' && window.dataLayer) {
		window['dataLayer'].push({ event: 'login', method: 'Email' });
	}
};

export const gaEventStartBooking = (referringUrl: string) => {
	if (typeof window !== 'undefined' && window.dataLayer) {
		window['dataLayer'].push({ event: 'start_booking', referring_url: referringUrl });
	}
};

export const gaEventSelectLocation = (locationId: string) => {
	if (typeof window !== 'undefined' && window.dataLayer) {
		window['dataLayer'].push({ event: 'select_location', location_id: locationId });
	}
};

export const gaEventSelectSchedule = () => {
	if (typeof window !== 'undefined' && window.dataLayer) {
		window['dataLayer'].push({ event: 'select_schedule' });
	}
};

export const gaEventAddToCart = (value: string, itemId: string, itemName: string) => {
	if (typeof window !== 'undefined' && window.dataLayer) {
		window['dataLayer'].push({
			event: 'add_to_cart',
			currency: 'USD',
			value: value,
			items: { item_id: itemId, item_name: itemName },
		});
	}
};

export const gaEventSignUp = () => {
	if (typeof window !== 'undefined' && window.dataLayer) {
		window['dataLayer'].push({ event: 'sign_up', method: 'Email' });
	}
};

export const gaEventPurchase = (
	transactionId: string,
	value: string,
	itemId: string,
	itemName: string
) => {
	if (typeof window !== 'undefined' && window.dataLayer) {
		window['dataLayer'].push({
			event: 'purchase',
			currency: 'USD',
			transaction_id: transactionId,
			value: value,
			items: { item_id: itemId, item_name: itemName },
		});
	}
};

export const gaEventError = (description: string, details: string, fatal: boolean) => {
	if (typeof window !== 'undefined' && window.dataLayer) {
		window['dataLayer'].push({
			event: 'exception',
			description: description,
			details: details,
			fatal: fatal,
		});
	}
};
