import React from 'react';
import { Link } from 'gatsby';

import logo from '../../img/navbar/lovelivedance_logo_small.png';
import * as Styles from './Logo.module.scss';

export default function Logo() {
	return (
		<div className={Styles.navbarBrand}>
			<Link to="/">
				<img src={logo} alt="Love.Live.Dance" width="200px" height="64px" />
			</Link>
		</div>
	);
}
